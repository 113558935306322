'use strict';

var cov_dwvln0vpb = function () {
  var path = '/home/piesevag/volane.piesevag.ro/src/app/environment/env.constant.js',
      hash = '524f1a66423191b7d41b0d566a428491389d5df5',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/home/piesevag/volane.piesevag.ro/src/app/environment/env.constant.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 6,
          column: 5
        }
      }
    },
    fnMap: {},
    branchMap: {},
    s: {
      '0': 0
    },
    f: {},
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_dwvln0vpb.s[0]++;
angular.module('app').constant('env', {
  appHost: 'http://api.piesevag.ro',
  apiPrefix: 'api'
});