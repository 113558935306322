'use strict';

var cov_1ak1s5izep = function () {
  var path = '/home/piesevag/volane.piesevag.ro/src/routes.js',
      hash = '7e0d6869c2c17570b9b2f4b46a0002c9fdc4d240',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/home/piesevag/volane.piesevag.ro/src/routes.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 3,
          column: 24
        }
      },
      '1': {
        start: {
          line: 7,
          column: 2
        },
        end: {
          line: 7,
          column: 52
        }
      },
      '2': {
        start: {
          line: 8,
          column: 2
        },
        end: {
          line: 8,
          column: 36
        }
      },
      '3': {
        start: {
          line: 10,
          column: 2
        },
        end: {
          line: 25,
          column: 7
        }
      },
      '4': {
        start: {
          line: 16,
          column: 10
        },
        end: {
          line: 16,
          column: 35
        }
      },
      '5': {
        start: {
          line: 19,
          column: 10
        },
        end: {
          line: 19,
          column: 42
        }
      },
      '6': {
        start: {
          line: 22,
          column: 10
        },
        end: {
          line: 22,
          column: 38
        }
      }
    },
    fnMap: {
      '0': {
        name: 'routesConfig',
        decl: {
          start: {
            line: 6,
            column: 9
          },
          end: {
            line: 6,
            column: 21
          }
        },
        loc: {
          start: {
            line: 6,
            column: 77
          },
          end: {
            line: 26,
            column: 1
          }
        },
        line: 6
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 15,
            column: 16
          },
          end: {
            line: 15,
            column: 17
          }
        },
        loc: {
          start: {
            line: 15,
            column: 34
          },
          end: {
            line: 17,
            column: 9
          }
        },
        line: 15
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 18,
            column: 20
          },
          end: {
            line: 18,
            column: 21
          }
        },
        loc: {
          start: {
            line: 18,
            column: 43
          },
          end: {
            line: 20,
            column: 9
          }
        },
        line: 18
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 21,
            column: 16
          },
          end: {
            line: 21,
            column: 17
          }
        },
        loc: {
          start: {
            line: 21,
            column: 39
          },
          end: {
            line: 23,
            column: 9
          }
        },
        line: 21
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1ak1s5izep.s[0]++;
angular.module('app').config(routesConfig);

/** @ngInject */
function routesConfig($stateProvider, $urlRouterProvider, $locationProvider) {
  cov_1ak1s5izep.f[0]++;
  cov_1ak1s5izep.s[1]++;

  $locationProvider.html5Mode(true).hashPrefix('!');
  cov_1ak1s5izep.s[2]++;
  $urlRouterProvider.otherwise('/');

  cov_1ak1s5izep.s[3]++;
  $stateProvider.state('app', {
    url: '/',
    component: 'app',
    resolve: {
      access: function access(Access) {
        cov_1ak1s5izep.f[1]++;
        cov_1ak1s5izep.s[4]++;

        return Access.isLogged();
      },
      Categories: function Categories(MainFactory) {
        cov_1ak1s5izep.f[2]++;
        cov_1ak1s5izep.s[5]++;

        return MainFactory.categories();
      },
      Wheels: function Wheels(MainFactory) {
        cov_1ak1s5izep.f[3]++;
        cov_1ak1s5izep.s[6]++;

        return MainFactory.wheels();
      }
    }
  });
}