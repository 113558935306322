'use strict';

var cov_1dxpjyptdn = function () {
  var path = '/home/piesevag/volane.piesevag.ro/src/app/components/header/header.js',
      hash = '172d4c9af975acb2885b018d1fd1de40a04cd85d',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/home/piesevag/volane.piesevag.ro/src/app/components/header/header.js',
    statementMap: {
      '0': {
        start: {
          line: 4,
          column: 15
        },
        end: {
          line: 4,
          column: 19
        }
      },
      '1': {
        start: {
          line: 5,
          column: 4
        },
        end: {
          line: 5,
          column: 19
        }
      },
      '2': {
        start: {
          line: 7,
          column: 4
        },
        end: {
          line: 7,
          column: 51
        }
      },
      '3': {
        start: {
          line: 9,
          column: 4
        },
        end: {
          line: 22,
          column: 9
        }
      },
      '4': {
        start: {
          line: 11,
          column: 8
        },
        end: {
          line: 17,
          column: 9
        }
      },
      '5': {
        start: {
          line: 12,
          column: 10
        },
        end: {
          line: 12,
          column: 29
        }
      },
      '6': {
        start: {
          line: 13,
          column: 10
        },
        end: {
          line: 16,
          column: 15
        }
      },
      '7': {
        start: {
          line: 15,
          column: 14
        },
        end: {
          line: 15,
          column: 48
        }
      },
      '8': {
        start: {
          line: 19,
          column: 8
        },
        end: {
          line: 21,
          column: 9
        }
      },
      '9': {
        start: {
          line: 20,
          column: 10
        },
        end: {
          line: 20,
          column: 29
        }
      },
      '10': {
        start: {
          line: 24,
          column: 4
        },
        end: {
          line: 27,
          column: 9
        }
      },
      '11': {
        start: {
          line: 26,
          column: 8
        },
        end: {
          line: 26,
          column: 41
        }
      },
      '12': {
        start: {
          line: 29,
          column: 4
        },
        end: {
          line: 31,
          column: 7
        }
      },
      '13': {
        start: {
          line: 30,
          column: 6
        },
        end: {
          line: 30,
          column: 21
        }
      },
      '14': {
        start: {
          line: 33,
          column: 4
        },
        end: {
          line: 37,
          column: 6
        }
      },
      '15': {
        start: {
          line: 34,
          column: 6
        },
        end: {
          line: 34,
          column: 33
        }
      },
      '16': {
        start: {
          line: 35,
          column: 6
        },
        end: {
          line: 35,
          column: 31
        }
      },
      '17': {
        start: {
          line: 36,
          column: 6
        },
        end: {
          line: 36,
          column: 25
        }
      },
      '18': {
        start: {
          line: 41,
          column: 0
        },
        end: {
          line: 47,
          column: 5
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 3,
            column: 2
          },
          end: {
            line: 3,
            column: 3
          }
        },
        loc: {
          start: {
            line: 3,
            column: 72
          },
          end: {
            line: 38,
            column: 3
          }
        },
        line: 3
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 10,
            column: 12
          },
          end: {
            line: 10,
            column: 13
          }
        },
        loc: {
          start: {
            line: 10,
            column: 19
          },
          end: {
            line: 18,
            column: 7
          }
        },
        line: 10
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 14,
            column: 18
          },
          end: {
            line: 14,
            column: 19
          }
        },
        loc: {
          start: {
            line: 14,
            column: 25
          },
          end: {
            line: 16,
            column: 13
          }
        },
        line: 14
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 18,
            column: 9
          },
          end: {
            line: 18,
            column: 10
          }
        },
        loc: {
          start: {
            line: 18,
            column: 16
          },
          end: {
            line: 22,
            column: 7
          }
        },
        line: 18
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 25,
            column: 12
          },
          end: {
            line: 25,
            column: 13
          }
        },
        loc: {
          start: {
            line: 25,
            column: 19
          },
          end: {
            line: 27,
            column: 7
          }
        },
        line: 25
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 29,
            column: 52
          },
          end: {
            line: 29,
            column: 53
          }
        },
        loc: {
          start: {
            line: 29,
            column: 58
          },
          end: {
            line: 31,
            column: 5
          }
        },
        line: 29
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 33,
            column: 16
          },
          end: {
            line: 33,
            column: 17
          }
        },
        loc: {
          start: {
            line: 33,
            column: 22
          },
          end: {
            line: 37,
            column: 5
          }
        },
        line: 33
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 11,
            column: 8
          },
          end: {
            line: 17,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 11,
            column: 8
          },
          end: {
            line: 17,
            column: 9
          }
        }, {
          start: {
            line: 11,
            column: 8
          },
          end: {
            line: 17,
            column: 9
          }
        }],
        line: 11
      },
      '1': {
        loc: {
          start: {
            line: 19,
            column: 8
          },
          end: {
            line: 21,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 19,
            column: 8
          },
          end: {
            line: 21,
            column: 9
          }
        }, {
          start: {
            line: 19,
            column: 8
          },
          end: {
            line: 21,
            column: 9
          }
        }],
        line: 19
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var HeaderController =
/** @ngInject */
function HeaderController(LoggedUser, MainFactory, $rootScope, AuthFactory, $state) {
  _classCallCheck(this, HeaderController);

  cov_1dxpjyptdn.f[0]++;

  var vm = (cov_1dxpjyptdn.s[0]++, this);
  cov_1dxpjyptdn.s[1]++;
  vm.user = null;

  cov_1dxpjyptdn.s[2]++;
  vm.showMenu = $state.$current.name !== 'app';

  cov_1dxpjyptdn.s[3]++;
  LoggedUser.getData().then(function (rsp) {
    cov_1dxpjyptdn.f[1]++;
    cov_1dxpjyptdn.s[4]++;

    if (rsp.success) {
      cov_1dxpjyptdn.b[0][0]++;
      cov_1dxpjyptdn.s[5]++;

      vm.user = rsp.user;
      cov_1dxpjyptdn.s[6]++;
      MainFactory.categories().then(function (rsp) {
        cov_1dxpjyptdn.f[2]++;
        cov_1dxpjyptdn.s[7]++;

        vm.categories = rsp.data.response;
      });
    } else {
      cov_1dxpjyptdn.b[0][1]++;
    }
  }, function (err) {
    cov_1dxpjyptdn.f[3]++;
    cov_1dxpjyptdn.s[8]++;

    if (err.error === 'token_expired') {
      cov_1dxpjyptdn.b[1][0]++;
      cov_1dxpjyptdn.s[9]++;

      $state.go('login');
    } else {
      cov_1dxpjyptdn.b[1][1]++;
    }
  });

  cov_1dxpjyptdn.s[10]++;
  MainFactory.cartContent().then(function (rsp) {
    cov_1dxpjyptdn.f[4]++;
    cov_1dxpjyptdn.s[11]++;

    vm.cartCount = rsp.data.response;
  });

  cov_1dxpjyptdn.s[12]++;
  vm.updateCart = $rootScope.$on('added-to-cart', function () {
    cov_1dxpjyptdn.f[5]++;
    cov_1dxpjyptdn.s[13]++;

    vm.cartCount++;
  });

  cov_1dxpjyptdn.s[14]++;
  vm.logout = function () {
    cov_1dxpjyptdn.f[6]++;
    cov_1dxpjyptdn.s[15]++;

    AuthFactory.destroyToken();
    cov_1dxpjyptdn.s[16]++;
    LoggedUser.destroyUser();
    cov_1dxpjyptdn.s[17]++;
    $state.go('login');
  };
};

cov_1dxpjyptdn.s[18]++;


angular.module('app').component('cshopHeader', {
  templateUrl: 'app/components/header/header.html',
  controller: HeaderController,
  controllerAs: 'vm'
});