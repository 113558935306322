'use strict';

var cov_1a9iyj5bdg = function () {
  var path = '/home/piesevag/volane.piesevag.ro/src/app/factories/access.factory.js',
      hash = '8cff672a7aa2e65fef95843a9aaefa9db391a53c',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/home/piesevag/volane.piesevag.ro/src/app/factories/access.factory.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 37,
          column: 5
        }
      },
      '1': {
        start: {
          line: 4,
          column: 2
        },
        end: {
          line: 6,
          column: 31
        }
      },
      '2': {
        start: {
          line: 10,
          column: 19
        },
        end: {
          line: 34,
          column: 5
        }
      },
      '3': {
        start: {
          line: 15,
          column: 25
        },
        end: {
          line: 15,
          column: 35
        }
      },
      '4': {
        start: {
          line: 16,
          column: 8
        },
        end: {
          line: 18,
          column: 9
        }
      },
      '5': {
        start: {
          line: 17,
          column: 10
        },
        end: {
          line: 17,
          column: 57
        }
      },
      '6': {
        start: {
          line: 19,
          column: 8
        },
        end: {
          line: 27,
          column: 13
        }
      },
      '7': {
        start: {
          line: 21,
          column: 12
        },
        end: {
          line: 23,
          column: 13
        }
      },
      '8': {
        start: {
          line: 22,
          column: 14
        },
        end: {
          line: 22,
          column: 42
        }
      },
      '9': {
        start: {
          line: 24,
          column: 12
        },
        end: {
          line: 24,
          column: 59
        }
      },
      '10': {
        start: {
          line: 26,
          column: 12
        },
        end: {
          line: 26,
          column: 59
        }
      },
      '11': {
        start: {
          line: 28,
          column: 8
        },
        end: {
          line: 28,
          column: 32
        }
      },
      '12': {
        start: {
          line: 31,
          column: 25
        },
        end: {
          line: 31,
          column: 35
        }
      },
      '13': {
        start: {
          line: 32,
          column: 8
        },
        end: {
          line: 32,
          column: 30
        }
      },
      '14': {
        start: {
          line: 35,
          column: 4
        },
        end: {
          line: 35,
          column: 18
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 1,
            column: 1
          },
          end: {
            line: 1,
            column: 2
          }
        },
        loc: {
          start: {
            line: 1,
            column: 13
          },
          end: {
            line: 37,
            column: 1
          }
        },
        line: 1
      },
      '1': {
        name: 'Access',
        decl: {
          start: {
            line: 9,
            column: 11
          },
          end: {
            line: 9,
            column: 17
          }
        },
        loc: {
          start: {
            line: 9,
            column: 47
          },
          end: {
            line: 36,
            column: 3
          }
        },
        line: 9
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 14,
            column: 16
          },
          end: {
            line: 14,
            column: 17
          }
        },
        loc: {
          start: {
            line: 14,
            column: 27
          },
          end: {
            line: 29,
            column: 7
          }
        },
        line: 14
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 20,
            column: 16
          },
          end: {
            line: 20,
            column: 17
          }
        },
        loc: {
          start: {
            line: 20,
            column: 23
          },
          end: {
            line: 25,
            column: 11
          }
        },
        line: 20
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 25,
            column: 13
          },
          end: {
            line: 25,
            column: 14
          }
        },
        loc: {
          start: {
            line: 25,
            column: 19
          },
          end: {
            line: 27,
            column: 11
          }
        },
        line: 25
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 30,
            column: 14
          },
          end: {
            line: 30,
            column: 15
          }
        },
        loc: {
          start: {
            line: 30,
            column: 20
          },
          end: {
            line: 33,
            column: 7
          }
        },
        line: 30
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 16,
            column: 8
          },
          end: {
            line: 18,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 16,
            column: 8
          },
          end: {
            line: 18,
            column: 9
          }
        }, {
          start: {
            line: 16,
            column: 8
          },
          end: {
            line: 18,
            column: 9
          }
        }],
        line: 16
      },
      '1': {
        loc: {
          start: {
            line: 21,
            column: 12
          },
          end: {
            line: 23,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 21,
            column: 12
          },
          end: {
            line: 23,
            column: 13
          }
        }, {
          start: {
            line: 21,
            column: 12
          },
          end: {
            line: 23,
            column: 13
          }
        }],
        line: 21
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1a9iyj5bdg.s[0]++;
(function () {
  'use strict';

  cov_1a9iyj5bdg.f[0]++;
  cov_1a9iyj5bdg.s[1]++;
  angular.module('app').factory('Access', Access);

  /** @ngInject */
  function Access($q, LoggedUser, AuthFactory) {
    cov_1a9iyj5bdg.f[1]++;

    var Access = (cov_1a9iyj5bdg.s[2]++, {
      OK: 200,
      UNAUTHORIZED: 401,
      FORBIDDEN: 403,
      isLogged: function isLogged(refresh) {
        cov_1a9iyj5bdg.f[2]++;

        var deferred = (cov_1a9iyj5bdg.s[3]++, $q.defer());
        cov_1a9iyj5bdg.s[4]++;
        if (AuthFactory.getSimpleToken() === '') {
          cov_1a9iyj5bdg.b[0][0]++;
          cov_1a9iyj5bdg.s[5]++;

          deferred.reject({ reason: Access.UNAUTHORIZED });
        } else {
          cov_1a9iyj5bdg.b[0][1]++;
        }
        cov_1a9iyj5bdg.s[6]++;
        LoggedUser.getData(AuthFactory.getToken(), refresh).then(function (rsp) {
          cov_1a9iyj5bdg.f[3]++;
          cov_1a9iyj5bdg.s[7]++;

          if (rsp.success === true) {
            cov_1a9iyj5bdg.b[1][0]++;
            cov_1a9iyj5bdg.s[8]++;

            deferred.resolve(Access.OK);
          } else {
            cov_1a9iyj5bdg.b[1][1]++;
          }
          cov_1a9iyj5bdg.s[9]++;
          deferred.reject({ reason: Access.UNAUTHORIZED });
        }, function () {
          cov_1a9iyj5bdg.f[4]++;
          cov_1a9iyj5bdg.s[10]++;

          deferred.reject({ reason: Access.UNAUTHORIZED });
        });
        cov_1a9iyj5bdg.s[11]++;
        return deferred.promise;
      },
      logged: function logged() {
        cov_1a9iyj5bdg.f[5]++;

        var deferred = (cov_1a9iyj5bdg.s[12]++, $q.defer());
        cov_1a9iyj5bdg.s[13]++;
        deferred.resolve(200);
      }
    });
    cov_1a9iyj5bdg.s[14]++;
    return Access;
  }
})();