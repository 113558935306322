'use strict';

var cov_2mraee949a = function () {
  var path = '/home/piesevag/volane.piesevag.ro/src/app/factories/user.factory.js',
      hash = '7b7654e882937b825f6cc77ae7166777e7cf469f',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/home/piesevag/volane.piesevag.ro/src/app/factories/user.factory.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 58,
          column: 5
        }
      },
      '1': {
        start: {
          line: 4,
          column: 2
        },
        end: {
          line: 6,
          column: 39
        }
      },
      '2': {
        start: {
          line: 10,
          column: 15
        },
        end: {
          line: 10,
          column: 19
        }
      },
      '3': {
        start: {
          line: 12,
          column: 20
        },
        end: {
          line: 29,
          column: 5
        }
      },
      '4': {
        start: {
          line: 13,
          column: 23
        },
        end: {
          line: 13,
          column: 33
        }
      },
      '5': {
        start: {
          line: 14,
          column: 6
        },
        end: {
          line: 16,
          column: 7
        }
      },
      '6': {
        start: {
          line: 15,
          column: 8
        },
        end: {
          line: 15,
          column: 20
        }
      },
      '7': {
        start: {
          line: 17,
          column: 6
        },
        end: {
          line: 27,
          column: 7
        }
      },
      '8': {
        start: {
          line: 18,
          column: 8
        },
        end: {
          line: 18,
          column: 31
        }
      },
      '9': {
        start: {
          line: 20,
          column: 8
        },
        end: {
          line: 26,
          column: 13
        }
      },
      '10': {
        start: {
          line: 22,
          column: 12
        },
        end: {
          line: 22,
          column: 28
        }
      },
      '11': {
        start: {
          line: 23,
          column: 12
        },
        end: {
          line: 23,
          column: 35
        }
      },
      '12': {
        start: {
          line: 25,
          column: 12
        },
        end: {
          line: 25,
          column: 33
        }
      },
      '13': {
        start: {
          line: 28,
          column: 6
        },
        end: {
          line: 28,
          column: 30
        }
      },
      '14': {
        start: {
          line: 31,
          column: 20
        },
        end: {
          line: 33,
          column: 5
        }
      },
      '15': {
        start: {
          line: 32,
          column: 23
        },
        end: {
          line: 32,
          column: 33
        }
      },
      '16': {
        start: {
          line: 35,
          column: 30
        },
        end: {
          line: 45,
          column: 5
        }
      },
      '17': {
        start: {
          line: 36,
          column: 22
        },
        end: {
          line: 36,
          column: 32
        }
      },
      '18': {
        start: {
          line: 37,
          column: 6
        },
        end: {
          line: 43,
          column: 11
        }
      },
      '19': {
        start: {
          line: 39,
          column: 10
        },
        end: {
          line: 39,
          column: 36
        }
      },
      '20': {
        start: {
          line: 42,
          column: 10
        },
        end: {
          line: 42,
          column: 28
        }
      },
      '21': {
        start: {
          line: 44,
          column: 6
        },
        end: {
          line: 44,
          column: 29
        }
      },
      '22': {
        start: {
          line: 47,
          column: 24
        },
        end: {
          line: 49,
          column: 5
        }
      },
      '23': {
        start: {
          line: 48,
          column: 6
        },
        end: {
          line: 48,
          column: 18
        }
      },
      '24': {
        start: {
          line: 51,
          column: 4
        },
        end: {
          line: 56,
          column: 6
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 1,
            column: 1
          },
          end: {
            line: 1,
            column: 2
          }
        },
        loc: {
          start: {
            line: 1,
            column: 13
          },
          end: {
            line: 58,
            column: 1
          }
        },
        line: 1
      },
      '1': {
        name: 'LoggedUser',
        decl: {
          start: {
            line: 9,
            column: 11
          },
          end: {
            line: 9,
            column: 21
          }
        },
        loc: {
          start: {
            line: 9,
            column: 38
          },
          end: {
            line: 57,
            column: 3
          }
        },
        line: 9
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 12,
            column: 20
          },
          end: {
            line: 12,
            column: 21
          }
        },
        loc: {
          start: {
            line: 12,
            column: 46
          },
          end: {
            line: 29,
            column: 5
          }
        },
        line: 12
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 21,
            column: 16
          },
          end: {
            line: 21,
            column: 17
          }
        },
        loc: {
          start: {
            line: 21,
            column: 23
          },
          end: {
            line: 24,
            column: 11
          }
        },
        line: 21
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 24,
            column: 13
          },
          end: {
            line: 24,
            column: 14
          }
        },
        loc: {
          start: {
            line: 24,
            column: 20
          },
          end: {
            line: 26,
            column: 11
          }
        },
        line: 24
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 31,
            column: 20
          },
          end: {
            line: 31,
            column: 21
          }
        },
        loc: {
          start: {
            line: 31,
            column: 32
          },
          end: {
            line: 33,
            column: 5
          }
        },
        line: 31
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 35,
            column: 30
          },
          end: {
            line: 35,
            column: 31
          }
        },
        loc: {
          start: {
            line: 35,
            column: 42
          },
          end: {
            line: 45,
            column: 5
          }
        },
        line: 35
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 38,
            column: 14
          },
          end: {
            line: 38,
            column: 15
          }
        },
        loc: {
          start: {
            line: 38,
            column: 26
          },
          end: {
            line: 40,
            column: 9
          }
        },
        line: 38
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 41,
            column: 15
          },
          end: {
            line: 41,
            column: 16
          }
        },
        loc: {
          start: {
            line: 41,
            column: 21
          },
          end: {
            line: 43,
            column: 9
          }
        },
        line: 41
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 47,
            column: 24
          },
          end: {
            line: 47,
            column: 25
          }
        },
        loc: {
          start: {
            line: 47,
            column: 36
          },
          end: {
            line: 49,
            column: 5
          }
        },
        line: 47
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 14,
            column: 6
          },
          end: {
            line: 16,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 14,
            column: 6
          },
          end: {
            line: 16,
            column: 7
          }
        }, {
          start: {
            line: 14,
            column: 6
          },
          end: {
            line: 16,
            column: 7
          }
        }],
        line: 14
      },
      '1': {
        loc: {
          start: {
            line: 17,
            column: 6
          },
          end: {
            line: 27,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 17,
            column: 6
          },
          end: {
            line: 27,
            column: 7
          }
        }, {
          start: {
            line: 17,
            column: 6
          },
          end: {
            line: 27,
            column: 7
          }
        }],
        line: 17
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_2mraee949a.s[0]++;
(function () {
  'use strict';

  cov_2mraee949a.f[0]++;
  cov_2mraee949a.s[1]++;
  angular.module('app').factory('LoggedUser', LoggedUser);

  /** @ngInject */
  function LoggedUser($q, $http, Api) {
    cov_2mraee949a.f[1]++;

    var data = (cov_2mraee949a.s[2]++, null);

    cov_2mraee949a.s[3]++;
    var getData = function getData(token, refresh) {
      cov_2mraee949a.f[2]++;

      var deferred = (cov_2mraee949a.s[4]++, $q.defer());
      cov_2mraee949a.s[5]++;
      if (typeof refresh !== 'undefined') {
        cov_2mraee949a.b[0][0]++;
        cov_2mraee949a.s[6]++;

        data = null;
      } else {
        cov_2mraee949a.b[0][1]++;
      }
      cov_2mraee949a.s[7]++;
      if (data !== null) {
        cov_2mraee949a.b[1][0]++;
        cov_2mraee949a.s[8]++;

        deferred.resolve(data);
      } else {
        cov_2mraee949a.b[1][1]++;
        cov_2mraee949a.s[9]++;

        $http.post(Api.generate('admin', 'auth', 'me'), { token: token }).then(function (rsp) {
          cov_2mraee949a.f[3]++;
          cov_2mraee949a.s[10]++;

          data = rsp.data;
          cov_2mraee949a.s[11]++;
          deferred.resolve(data);
        }, function (err) {
          cov_2mraee949a.f[4]++;
          cov_2mraee949a.s[12]++;

          deferred.reject(err);
        });
      }
      cov_2mraee949a.s[13]++;
      return deferred.promise;
    };

    cov_2mraee949a.s[14]++;
    var setData = function setData() {
      cov_2mraee949a.f[5]++;

      var deferred = (cov_2mraee949a.s[15]++, $q.defer());
    };

    cov_2mraee949a.s[16]++;
    var getDataWithReject = function getDataWithReject() {
      cov_2mraee949a.f[6]++;

      var defferd = (cov_2mraee949a.s[17]++, $q.defer());
      cov_2mraee949a.s[18]++;
      getData().then(function (response) {
        cov_2mraee949a.f[7]++;
        cov_2mraee949a.s[19]++;

        defferd.resolve(response);
      }).catch(function () {
        cov_2mraee949a.f[8]++;
        cov_2mraee949a.s[20]++;

        defferd.resolve();
      });
      cov_2mraee949a.s[21]++;
      return defferd.promise;
    };

    cov_2mraee949a.s[22]++;
    var destroyUser = function destroyUser() {
      cov_2mraee949a.f[9]++;
      cov_2mraee949a.s[23]++;

      data = null;
    };

    cov_2mraee949a.s[24]++;
    return {
      getData: getData,
      getDataWithReject: getDataWithReject,
      setData: setData,
      destroyUser: destroyUser
    };
  }
})();