'use strict';

var cov_15pho3oqzx = function () {
  var path = '/home/piesevag/volane.piesevag.ro/src/app/main/main.factory.js',
      hash = 'a5792912f09a146a240aa98a26106e5a52f16187',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/home/piesevag/volane.piesevag.ro/src/app/main/main.factory.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 3,
          column: 39
        }
      },
      '1': {
        start: {
          line: 7,
          column: 2
        },
        end: {
          line: 56,
          column: 4
        }
      },
      '2': {
        start: {
          line: 9,
          column: 6
        },
        end: {
          line: 13,
          column: 9
        }
      },
      '3': {
        start: {
          line: 16,
          column: 6
        },
        end: {
          line: 20,
          column: 9
        }
      },
      '4': {
        start: {
          line: 23,
          column: 6
        },
        end: {
          line: 27,
          column: 9
        }
      },
      '5': {
        start: {
          line: 30,
          column: 6
        },
        end: {
          line: 34,
          column: 9
        }
      },
      '6': {
        start: {
          line: 37,
          column: 6
        },
        end: {
          line: 40,
          column: 9
        }
      },
      '7': {
        start: {
          line: 43,
          column: 6
        },
        end: {
          line: 47,
          column: 9
        }
      },
      '8': {
        start: {
          line: 50,
          column: 6
        },
        end: {
          line: 54,
          column: 9
        }
      }
    },
    fnMap: {
      '0': {
        name: 'MainFactory',
        decl: {
          start: {
            line: 6,
            column: 9
          },
          end: {
            line: 6,
            column: 20
          }
        },
        loc: {
          start: {
            line: 6,
            column: 33
          },
          end: {
            line: 57,
            column: 1
          }
        },
        line: 6
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 8,
            column: 12
          },
          end: {
            line: 8,
            column: 13
          }
        },
        loc: {
          start: {
            line: 8,
            column: 20
          },
          end: {
            line: 14,
            column: 5
          }
        },
        line: 8
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 15,
            column: 23
          },
          end: {
            line: 15,
            column: 24
          }
        },
        loc: {
          start: {
            line: 15,
            column: 31
          },
          end: {
            line: 21,
            column: 5
          }
        },
        line: 15
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 22,
            column: 16
          },
          end: {
            line: 22,
            column: 17
          }
        },
        loc: {
          start: {
            line: 22,
            column: 24
          },
          end: {
            line: 28,
            column: 5
          }
        },
        line: 22
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 29,
            column: 13
          },
          end: {
            line: 29,
            column: 14
          }
        },
        loc: {
          start: {
            line: 29,
            column: 21
          },
          end: {
            line: 35,
            column: 5
          }
        },
        line: 29
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 36,
            column: 17
          },
          end: {
            line: 36,
            column: 18
          }
        },
        loc: {
          start: {
            line: 36,
            column: 23
          },
          end: {
            line: 41,
            column: 5
          }
        },
        line: 36
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 42,
            column: 15
          },
          end: {
            line: 42,
            column: 16
          }
        },
        loc: {
          start: {
            line: 42,
            column: 23
          },
          end: {
            line: 48,
            column: 5
          }
        },
        line: 42
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 49,
            column: 16
          },
          end: {
            line: 49,
            column: 17
          }
        },
        loc: {
          start: {
            line: 49,
            column: 24
          },
          end: {
            line: 55,
            column: 5
          }
        },
        line: 49
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_15pho3oqzx.s[0]++;
angular.module('app').factory('MainFactory', MainFactory);

/** @ngInject */
function MainFactory(Api, $http) {
  cov_15pho3oqzx.f[0]++;
  cov_15pho3oqzx.s[1]++;

  return {
    wheels: function wheels(data) {
      cov_15pho3oqzx.f[1]++;
      cov_15pho3oqzx.s[2]++;

      return $http({
        url: Api.generate('admin', 'wheels', 'read'),
        method: 'POST',
        data: data
      });
    },
    wheelsForCategory: function wheelsForCategory(data) {
      cov_15pho3oqzx.f[2]++;
      cov_15pho3oqzx.s[3]++;

      return $http({
        url: Api.generate('admin', 'wheels', 'open-category'),
        method: 'POST',
        data: data
      });
    },
    categories: function categories(data) {
      cov_15pho3oqzx.f[3]++;
      cov_15pho3oqzx.s[4]++;

      return $http({
        url: Api.generate('admin', 'categories', 'read'),
        method: 'POST',
        data: data
      });
    },
    cartAdd: function cartAdd(data) {
      cov_15pho3oqzx.f[4]++;
      cov_15pho3oqzx.s[5]++;

      return $http({
        url: Api.generate('admin', 'cart', 'add'),
        method: 'POST',
        data: data
      });
    },
    cartContent: function cartContent() {
      cov_15pho3oqzx.f[5]++;
      cov_15pho3oqzx.s[6]++;

      return $http({
        url: Api.generate('admin', 'cart', 'get'),
        method: 'POST'
      });
    },
    saveOrder: function saveOrder(data) {
      cov_15pho3oqzx.f[6]++;
      cov_15pho3oqzx.s[7]++;

      return $http({
        url: Api.generate('admin', 'orders', 'create'),
        method: 'POST',
        data: data
      });
    },
    removeCart: function removeCart(data) {
      cov_15pho3oqzx.f[7]++;
      cov_15pho3oqzx.s[8]++;

      return $http({
        url: Api.generate('admin', 'cart', 'delete'),
        method: 'POST',
        data: data
      });
    }
  };
}