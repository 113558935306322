'use strict';

var cov_2p2qwfwcv = function () {
  var path = '/home/piesevag/volane.piesevag.ro/src/app/login/login.factory.js',
      hash = '432c2f2da08058cc15565f6ec486af87f56f3f32',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/home/piesevag/volane.piesevag.ro/src/app/login/login.factory.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 3,
          column: 41
        }
      },
      '1': {
        start: {
          line: 7,
          column: 2
        },
        end: {
          line: 15,
          column: 4
        }
      },
      '2': {
        start: {
          line: 9,
          column: 6
        },
        end: {
          line: 13,
          column: 9
        }
      }
    },
    fnMap: {
      '0': {
        name: 'LoginFactory',
        decl: {
          start: {
            line: 6,
            column: 9
          },
          end: {
            line: 6,
            column: 21
          }
        },
        loc: {
          start: {
            line: 6,
            column: 34
          },
          end: {
            line: 16,
            column: 1
          }
        },
        line: 6
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 8,
            column: 10
          },
          end: {
            line: 8,
            column: 11
          }
        },
        loc: {
          start: {
            line: 8,
            column: 18
          },
          end: {
            line: 14,
            column: 5
          }
        },
        line: 8
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0
    },
    f: {
      '0': 0,
      '1': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_2p2qwfwcv.s[0]++;
angular.module('app').factory('LoginFactory', LoginFactory);

/** @ngInject */
function LoginFactory(Api, $http) {
  cov_2p2qwfwcv.f[0]++;
  cov_2p2qwfwcv.s[1]++;

  return {
    auth: function auth(data) {
      cov_2p2qwfwcv.f[1]++;
      cov_2p2qwfwcv.s[2]++;

      return $http({
        url: Api.generate('public', 'login'),
        method: 'POST',
        data: data
      });
    }
  };
}