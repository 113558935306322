'use strict';

var cov_1fl80kmf1x = function () {
  var path = '/sites/front-volane.piesevag.ro/src/app/components/category-box/product-box.js',
      hash = 'b8bae712de6136051d695238e2a4539473ceefc8',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/sites/front-volane.piesevag.ro/src/app/components/category-box/product-box.js',
    statementMap: {
      '0': {
        start: {
          line: 4,
          column: 15
        },
        end: {
          line: 4,
          column: 19
        }
      },
      '1': {
        start: {
          line: 5,
          column: 4
        },
        end: {
          line: 5,
          column: 31
        }
      },
      '2': {
        start: {
          line: 7,
          column: 4
        },
        end: {
          line: 15,
          column: 6
        }
      },
      '3': {
        start: {
          line: 8,
          column: 6
        },
        end: {
          line: 10,
          column: 7
        }
      },
      '4': {
        start: {
          line: 9,
          column: 8
        },
        end: {
          line: 9,
          column: 146
        }
      },
      '5': {
        start: {
          line: 12,
          column: 6
        },
        end: {
          line: 14,
          column: 7
        }
      },
      '6': {
        start: {
          line: 13,
          column: 8
        },
        end: {
          line: 13,
          column: 35
        }
      },
      '7': {
        start: {
          line: 17,
          column: 4
        },
        end: {
          line: 19,
          column: 6
        }
      },
      '8': {
        start: {
          line: 18,
          column: 6
        },
        end: {
          line: 18,
          column: 35
        }
      },
      '9': {
        start: {
          line: 23,
          column: 0
        },
        end: {
          line: 33,
          column: 5
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 3,
            column: 2
          },
          end: {
            line: 3,
            column: 3
          }
        },
        loc: {
          start: {
            line: 3,
            column: 27
          },
          end: {
            line: 20,
            column: 3
          }
        },
        line: 3
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 7,
            column: 17
          },
          end: {
            line: 7,
            column: 18
          }
        },
        loc: {
          start: {
            line: 7,
            column: 23
          },
          end: {
            line: 15,
            column: 5
          }
        },
        line: 7
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 17,
            column: 21
          },
          end: {
            line: 17,
            column: 22
          }
        },
        loc: {
          start: {
            line: 17,
            column: 37
          },
          end: {
            line: 19,
            column: 5
          }
        },
        line: 17
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 8,
            column: 6
          },
          end: {
            line: 10,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 8,
            column: 6
          },
          end: {
            line: 10,
            column: 7
          }
        }, {
          start: {
            line: 8,
            column: 6
          },
          end: {
            line: 10,
            column: 7
          }
        }],
        line: 8
      },
      '1': {
        loc: {
          start: {
            line: 9,
            column: 24
          },
          end: {
            line: 9,
            column: 145
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 9,
            column: 62
          },
          end: {
            line: 9,
            column: 103
          }
        }, {
          start: {
            line: 9,
            column: 106
          },
          end: {
            line: 9,
            column: 145
          }
        }],
        line: 9
      },
      '2': {
        loc: {
          start: {
            line: 12,
            column: 6
          },
          end: {
            line: 14,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 12,
            column: 6
          },
          end: {
            line: 14,
            column: 7
          }
        }, {
          start: {
            line: 12,
            column: 6
          },
          end: {
            line: 14,
            column: 7
          }
        }],
        line: 12
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var ProductBoxController =
/** @ngInject */
function ProductBoxController(env, $state) {
  _classCallCheck(this, ProductBoxController);

  cov_1fl80kmf1x.f[0]++;

  var vm = (cov_1fl80kmf1x.s[0]++, this);
  cov_1fl80kmf1x.s[1]++;
  vm.currency = env.currency;

  cov_1fl80kmf1x.s[2]++;
  vm.$onInit = function () {
    cov_1fl80kmf1x.f[1]++;
    cov_1fl80kmf1x.s[3]++;

    if (vm.product.promo !== 0) {
      cov_1fl80kmf1x.b[0][0]++;
      cov_1fl80kmf1x.s[4]++;

      vm.promoLabel = vm.product.promoData.type === 'v' ? (cov_1fl80kmf1x.b[1][0]++, '-' + vm.product.promoData.value + ' lei') : (cov_1fl80kmf1x.b[1][1]++, '-' + vm.product.promoData.value + ' %');
    } else {
      cov_1fl80kmf1x.b[0][1]++;
    }

    cov_1fl80kmf1x.s[5]++;
    if (vm.product.price === null) {
      cov_1fl80kmf1x.b[2][0]++;
      cov_1fl80kmf1x.s[6]++;

      vm.pricePreLabel = 'De la';
    } else {
      cov_1fl80kmf1x.b[2][1]++;
    }
  };

  cov_1fl80kmf1x.s[7]++;
  vm.goToProduct = function (slug) {
    cov_1fl80kmf1x.f[2]++;
    cov_1fl80kmf1x.s[8]++;

    $state.go('product', { slug: slug });
  };
};

cov_1fl80kmf1x.s[9]++;


angular.module('app').component('productBox', {
  templateUrl: 'app/components/product-box/product-box.html',
  controller: ProductBoxController,
  controllerAs: 'vm',
  bindToController: true,
  bindings: {
    product: '='
  }
});