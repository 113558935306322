'use strict';

var cov_9khvr7qso = function () {
  var path = '/home/piesevag/volane.piesevag.ro/src/app/category-page/category.js',
      hash = '5b4f429743fbd402ea3c23dfeab182f7fef2eb59',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/home/piesevag/volane.piesevag.ro/src/app/category-page/category.js',
    statementMap: {
      '0': {
        start: {
          line: 4,
          column: 15
        },
        end: {
          line: 4,
          column: 19
        }
      },
      '1': {
        start: {
          line: 6,
          column: 4
        },
        end: {
          line: 9,
          column: 9
        }
      },
      '2': {
        start: {
          line: 8,
          column: 8
        },
        end: {
          line: 8,
          column: 38
        }
      },
      '3': {
        start: {
          line: 11,
          column: 4
        },
        end: {
          line: 14,
          column: 9
        }
      },
      '4': {
        start: {
          line: 13,
          column: 8
        },
        end: {
          line: 13,
          column: 40
        }
      },
      '5': {
        start: {
          line: 18,
          column: 0
        },
        end: {
          line: 24,
          column: 5
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 3,
            column: 2
          },
          end: {
            line: 3,
            column: 3
          }
        },
        loc: {
          start: {
            line: 3,
            column: 41
          },
          end: {
            line: 15,
            column: 3
          }
        },
        line: 3
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 7,
            column: 12
          },
          end: {
            line: 7,
            column: 13
          }
        },
        loc: {
          start: {
            line: 7,
            column: 19
          },
          end: {
            line: 9,
            column: 7
          }
        },
        line: 7
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 12,
            column: 12
          },
          end: {
            line: 12,
            column: 13
          }
        },
        loc: {
          start: {
            line: 12,
            column: 19
          },
          end: {
            line: 14,
            column: 7
          }
        },
        line: 12
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Category =
/** @ngInject */
function Category($stateParams, MainFactory) {
  _classCallCheck(this, Category);

  cov_9khvr7qso.f[0]++;

  var vm = (cov_9khvr7qso.s[0]++, this);

  cov_9khvr7qso.s[1]++;
  MainFactory.wheelsForCategory({ id: $stateParams.id }).then(function (rsp) {
    cov_9khvr7qso.f[1]++;
    cov_9khvr7qso.s[2]++;

    vm.wheels = rsp.data.response;
  });

  cov_9khvr7qso.s[3]++;
  MainFactory.categories({ id: $stateParams.id }).then(function (rsp) {
    cov_9khvr7qso.f[2]++;
    cov_9khvr7qso.s[4]++;

    vm.category = rsp.data.response;
  });
};

cov_9khvr7qso.s[5]++;


angular.module('app').component('category', {
  templateUrl: 'app/category-page/category.html',
  controller: Category,
  controllerAs: 'vm'
});