'use strict';

var cov_15lre8za3h = function () {
  var path = '/home/piesevag/volane.piesevag.ro/src/app/factories/auth.factory.js',
      hash = '2293388e8d671270bb16bf6cfc98f8ee708bfa94',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/home/piesevag/volane.piesevag.ro/src/app/factories/auth.factory.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 6,
          column: 39
        }
      },
      '1': {
        start: {
          line: 10,
          column: 2
        },
        end: {
          line: 10,
          column: 47
        }
      },
      '2': {
        start: {
          line: 15,
          column: 2
        },
        end: {
          line: 22,
          column: 4
        }
      },
      '3': {
        start: {
          line: 17,
          column: 6
        },
        end: {
          line: 19,
          column: 7
        }
      },
      '4': {
        start: {
          line: 18,
          column: 8
        },
        end: {
          line: 18,
          column: 62
        }
      },
      '5': {
        start: {
          line: 20,
          column: 6
        },
        end: {
          line: 20,
          column: 20
        }
      },
      '6': {
        start: {
          line: 27,
          column: 2
        },
        end: {
          line: 27,
          column: 53
        }
      },
      '7': {
        start: {
          line: 32,
          column: 17
        },
        end: {
          line: 39,
          column: 3
        }
      },
      '8': {
        start: {
          line: 42,
          column: 4
        },
        end: {
          line: 48,
          column: 5
        }
      },
      '9': {
        start: {
          line: 43,
          column: 6
        },
        end: {
          line: 43,
          column: 56
        }
      },
      '10': {
        start: {
          line: 44,
          column: 11
        },
        end: {
          line: 48,
          column: 5
        }
      },
      '11': {
        start: {
          line: 45,
          column: 6
        },
        end: {
          line: 45,
          column: 63
        }
      },
      '12': {
        start: {
          line: 47,
          column: 6
        },
        end: {
          line: 47,
          column: 58
        }
      },
      '13': {
        start: {
          line: 52,
          column: 4
        },
        end: {
          line: 68,
          column: 5
        }
      },
      '14': {
        start: {
          line: 53,
          column: 6
        },
        end: {
          line: 57,
          column: 7
        }
      },
      '15': {
        start: {
          line: 54,
          column: 8
        },
        end: {
          line: 54,
          column: 50
        }
      },
      '16': {
        start: {
          line: 56,
          column: 8
        },
        end: {
          line: 56,
          column: 18
        }
      },
      '17': {
        start: {
          line: 58,
          column: 11
        },
        end: {
          line: 68,
          column: 5
        }
      },
      '18': {
        start: {
          line: 59,
          column: 6
        },
        end: {
          line: 63,
          column: 7
        }
      },
      '19': {
        start: {
          line: 60,
          column: 8
        },
        end: {
          line: 60,
          column: 57
        }
      },
      '20': {
        start: {
          line: 62,
          column: 8
        },
        end: {
          line: 62,
          column: 18
        }
      },
      '21': {
        start: {
          line: 64,
          column: 11
        },
        end: {
          line: 68,
          column: 5
        }
      },
      '22': {
        start: {
          line: 65,
          column: 6
        },
        end: {
          line: 65,
          column: 37
        }
      },
      '23': {
        start: {
          line: 67,
          column: 6
        },
        end: {
          line: 67,
          column: 16
        }
      },
      '24': {
        start: {
          line: 72,
          column: 4
        },
        end: {
          line: 74,
          column: 5
        }
      },
      '25': {
        start: {
          line: 73,
          column: 6
        },
        end: {
          line: 73,
          column: 39
        }
      },
      '26': {
        start: {
          line: 76,
          column: 4
        },
        end: {
          line: 92,
          column: 5
        }
      },
      '27': {
        start: {
          line: 77,
          column: 6
        },
        end: {
          line: 81,
          column: 7
        }
      },
      '28': {
        start: {
          line: 78,
          column: 8
        },
        end: {
          line: 78,
          column: 62
        }
      },
      '29': {
        start: {
          line: 80,
          column: 8
        },
        end: {
          line: 80,
          column: 18
        }
      },
      '30': {
        start: {
          line: 82,
          column: 11
        },
        end: {
          line: 92,
          column: 5
        }
      },
      '31': {
        start: {
          line: 83,
          column: 6
        },
        end: {
          line: 87,
          column: 7
        }
      },
      '32': {
        start: {
          line: 84,
          column: 8
        },
        end: {
          line: 84,
          column: 69
        }
      },
      '33': {
        start: {
          line: 86,
          column: 8
        },
        end: {
          line: 86,
          column: 18
        }
      },
      '34': {
        start: {
          line: 88,
          column: 11
        },
        end: {
          line: 92,
          column: 5
        }
      },
      '35': {
        start: {
          line: 89,
          column: 6
        },
        end: {
          line: 89,
          column: 49
        }
      },
      '36': {
        start: {
          line: 91,
          column: 6
        },
        end: {
          line: 91,
          column: 16
        }
      },
      '37': {
        start: {
          line: 96,
          column: 4
        },
        end: {
          line: 102,
          column: 5
        }
      },
      '38': {
        start: {
          line: 97,
          column: 6
        },
        end: {
          line: 97,
          column: 44
        }
      },
      '39': {
        start: {
          line: 98,
          column: 11
        },
        end: {
          line: 102,
          column: 5
        }
      },
      '40': {
        start: {
          line: 99,
          column: 6
        },
        end: {
          line: 99,
          column: 51
        }
      },
      '41': {
        start: {
          line: 101,
          column: 6
        },
        end: {
          line: 101,
          column: 46
        }
      },
      '42': {
        start: {
          line: 106,
          column: 4
        },
        end: {
          line: 113,
          column: 6
        }
      },
      '43': {
        start: {
          line: 108,
          column: 8
        },
        end: {
          line: 110,
          column: 9
        }
      },
      '44': {
        start: {
          line: 109,
          column: 10
        },
        end: {
          line: 109,
          column: 60
        }
      },
      '45': {
        start: {
          line: 111,
          column: 8
        },
        end: {
          line: 111,
          column: 50
        }
      },
      '46': {
        start: {
          line: 116,
          column: 2
        },
        end: {
          line: 116,
          column: 16
        }
      }
    },
    fnMap: {
      '0': {
        name: 'lsp',
        decl: {
          start: {
            line: 9,
            column: 9
          },
          end: {
            line: 9,
            column: 12
          }
        },
        loc: {
          start: {
            line: 9,
            column: 42
          },
          end: {
            line: 11,
            column: 1
          }
        },
        line: 9
      },
      '1': {
        name: 'AuthInterceptor',
        decl: {
          start: {
            line: 14,
            column: 9
          },
          end: {
            line: 14,
            column: 24
          }
        },
        loc: {
          start: {
            line: 14,
            column: 38
          },
          end: {
            line: 23,
            column: 1
          }
        },
        line: 14
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 16,
            column: 13
          },
          end: {
            line: 16,
            column: 14
          }
        },
        loc: {
          start: {
            line: 16,
            column: 23
          },
          end: {
            line: 21,
            column: 5
          }
        },
        line: 16
      },
      '3': {
        name: 'hp',
        decl: {
          start: {
            line: 26,
            column: 9
          },
          end: {
            line: 26,
            column: 11
          }
        },
        loc: {
          start: {
            line: 26,
            column: 27
          },
          end: {
            line: 28,
            column: 1
          }
        },
        line: 26
      },
      '4': {
        name: 'AuthFactory',
        decl: {
          start: {
            line: 31,
            column: 9
          },
          end: {
            line: 31,
            column: 20
          }
        },
        loc: {
          start: {
            line: 31,
            column: 65
          },
          end: {
            line: 117,
            column: 1
          }
        },
        line: 31
      },
      '5': {
        name: 'setToken',
        decl: {
          start: {
            line: 41,
            column: 11
          },
          end: {
            line: 41,
            column: 19
          }
        },
        loc: {
          start: {
            line: 41,
            column: 28
          },
          end: {
            line: 49,
            column: 3
          }
        },
        line: 41
      },
      '6': {
        name: 'getSimpleToken',
        decl: {
          start: {
            line: 51,
            column: 11
          },
          end: {
            line: 51,
            column: 25
          }
        },
        loc: {
          start: {
            line: 51,
            column: 28
          },
          end: {
            line: 69,
            column: 3
          }
        },
        line: 51
      },
      '7': {
        name: 'getToken',
        decl: {
          start: {
            line: 71,
            column: 11
          },
          end: {
            line: 71,
            column: 19
          }
        },
        loc: {
          start: {
            line: 71,
            column: 22
          },
          end: {
            line: 93,
            column: 3
          }
        },
        line: 71
      },
      '8': {
        name: 'destroySession',
        decl: {
          start: {
            line: 95,
            column: 11
          },
          end: {
            line: 95,
            column: 25
          }
        },
        loc: {
          start: {
            line: 95,
            column: 28
          },
          end: {
            line: 103,
            column: 3
          }
        },
        line: 95
      },
      '9': {
        name: 'tokenInterceptor',
        decl: {
          start: {
            line: 105,
            column: 11
          },
          end: {
            line: 105,
            column: 27
          }
        },
        loc: {
          start: {
            line: 105,
            column: 30
          },
          end: {
            line: 114,
            column: 3
          }
        },
        line: 105
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 107,
            column: 16
          },
          end: {
            line: 107,
            column: 17
          }
        },
        loc: {
          start: {
            line: 107,
            column: 28
          },
          end: {
            line: 112,
            column: 7
          }
        },
        line: 107
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 17,
            column: 6
          },
          end: {
            line: 19,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 17,
            column: 6
          },
          end: {
            line: 19,
            column: 7
          }
        }, {
          start: {
            line: 17,
            column: 6
          },
          end: {
            line: 19,
            column: 7
          }
        }],
        line: 17
      },
      '1': {
        loc: {
          start: {
            line: 42,
            column: 4
          },
          end: {
            line: 48,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 42,
            column: 4
          },
          end: {
            line: 48,
            column: 5
          }
        }, {
          start: {
            line: 42,
            column: 4
          },
          end: {
            line: 48,
            column: 5
          }
        }],
        line: 42
      },
      '2': {
        loc: {
          start: {
            line: 44,
            column: 11
          },
          end: {
            line: 48,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 44,
            column: 11
          },
          end: {
            line: 48,
            column: 5
          }
        }, {
          start: {
            line: 44,
            column: 11
          },
          end: {
            line: 48,
            column: 5
          }
        }],
        line: 44
      },
      '3': {
        loc: {
          start: {
            line: 52,
            column: 4
          },
          end: {
            line: 68,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 52,
            column: 4
          },
          end: {
            line: 68,
            column: 5
          }
        }, {
          start: {
            line: 52,
            column: 4
          },
          end: {
            line: 68,
            column: 5
          }
        }],
        line: 52
      },
      '4': {
        loc: {
          start: {
            line: 53,
            column: 6
          },
          end: {
            line: 57,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 53,
            column: 6
          },
          end: {
            line: 57,
            column: 7
          }
        }, {
          start: {
            line: 53,
            column: 6
          },
          end: {
            line: 57,
            column: 7
          }
        }],
        line: 53
      },
      '5': {
        loc: {
          start: {
            line: 58,
            column: 11
          },
          end: {
            line: 68,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 58,
            column: 11
          },
          end: {
            line: 68,
            column: 5
          }
        }, {
          start: {
            line: 58,
            column: 11
          },
          end: {
            line: 68,
            column: 5
          }
        }],
        line: 58
      },
      '6': {
        loc: {
          start: {
            line: 59,
            column: 6
          },
          end: {
            line: 63,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 59,
            column: 6
          },
          end: {
            line: 63,
            column: 7
          }
        }, {
          start: {
            line: 59,
            column: 6
          },
          end: {
            line: 63,
            column: 7
          }
        }],
        line: 59
      },
      '7': {
        loc: {
          start: {
            line: 64,
            column: 11
          },
          end: {
            line: 68,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 64,
            column: 11
          },
          end: {
            line: 68,
            column: 5
          }
        }, {
          start: {
            line: 64,
            column: 11
          },
          end: {
            line: 68,
            column: 5
          }
        }],
        line: 64
      },
      '8': {
        loc: {
          start: {
            line: 72,
            column: 4
          },
          end: {
            line: 74,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 72,
            column: 4
          },
          end: {
            line: 74,
            column: 5
          }
        }, {
          start: {
            line: 72,
            column: 4
          },
          end: {
            line: 74,
            column: 5
          }
        }],
        line: 72
      },
      '9': {
        loc: {
          start: {
            line: 76,
            column: 4
          },
          end: {
            line: 92,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 76,
            column: 4
          },
          end: {
            line: 92,
            column: 5
          }
        }, {
          start: {
            line: 76,
            column: 4
          },
          end: {
            line: 92,
            column: 5
          }
        }],
        line: 76
      },
      '10': {
        loc: {
          start: {
            line: 77,
            column: 6
          },
          end: {
            line: 81,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 77,
            column: 6
          },
          end: {
            line: 81,
            column: 7
          }
        }, {
          start: {
            line: 77,
            column: 6
          },
          end: {
            line: 81,
            column: 7
          }
        }],
        line: 77
      },
      '11': {
        loc: {
          start: {
            line: 82,
            column: 11
          },
          end: {
            line: 92,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 82,
            column: 11
          },
          end: {
            line: 92,
            column: 5
          }
        }, {
          start: {
            line: 82,
            column: 11
          },
          end: {
            line: 92,
            column: 5
          }
        }],
        line: 82
      },
      '12': {
        loc: {
          start: {
            line: 83,
            column: 6
          },
          end: {
            line: 87,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 83,
            column: 6
          },
          end: {
            line: 87,
            column: 7
          }
        }, {
          start: {
            line: 83,
            column: 6
          },
          end: {
            line: 87,
            column: 7
          }
        }],
        line: 83
      },
      '13': {
        loc: {
          start: {
            line: 88,
            column: 11
          },
          end: {
            line: 92,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 88,
            column: 11
          },
          end: {
            line: 92,
            column: 5
          }
        }, {
          start: {
            line: 88,
            column: 11
          },
          end: {
            line: 92,
            column: 5
          }
        }],
        line: 88
      },
      '14': {
        loc: {
          start: {
            line: 96,
            column: 4
          },
          end: {
            line: 102,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 96,
            column: 4
          },
          end: {
            line: 102,
            column: 5
          }
        }, {
          start: {
            line: 96,
            column: 4
          },
          end: {
            line: 102,
            column: 5
          }
        }],
        line: 96
      },
      '15': {
        loc: {
          start: {
            line: 98,
            column: 11
          },
          end: {
            line: 102,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 98,
            column: 11
          },
          end: {
            line: 102,
            column: 5
          }
        }, {
          start: {
            line: 98,
            column: 11
          },
          end: {
            line: 102,
            column: 5
          }
        }],
        line: 98
      },
      '16': {
        loc: {
          start: {
            line: 108,
            column: 8
          },
          end: {
            line: 110,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 108,
            column: 8
          },
          end: {
            line: 110,
            column: 9
          }
        }, {
          start: {
            line: 108,
            column: 8
          },
          end: {
            line: 110,
            column: 9
          }
        }],
        line: 108
      },
      '17': {
        loc: {
          start: {
            line: 111,
            column: 15
          },
          end: {
            line: 111,
            column: 49
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 111,
            column: 15
          },
          end: {
            line: 111,
            column: 28
          }
        }, {
          start: {
            line: 111,
            column: 32
          },
          end: {
            line: 111,
            column: 49
          }
        }],
        line: 111
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0],
      '15': [0, 0],
      '16': [0, 0],
      '17': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_15lre8za3h.s[0]++;
angular.module('app').config(lsp).factory('AuthInterceptor', AuthInterceptor).config(hp).factory('AuthFactory', AuthFactory);

/** @ngInject */
function lsp(localStorageServiceProvider) {
  cov_15lre8za3h.f[0]++;
  cov_15lre8za3h.s[1]++;

  localStorageServiceProvider.setPrefix('IND');
};

/** @ngInject */
function AuthInterceptor(AuthFactory) {
  cov_15lre8za3h.f[1]++;
  cov_15lre8za3h.s[2]++;

  return {
    request: function request(config) {
      cov_15lre8za3h.f[2]++;
      cov_15lre8za3h.s[3]++;

      if (config.url.indexOf('http') !== -1) {
        cov_15lre8za3h.b[0][0]++;
        cov_15lre8za3h.s[4]++;

        config.headers.Authorization = AuthFactory.getToken();
      } else {
        cov_15lre8za3h.b[0][1]++;
      }
      cov_15lre8za3h.s[5]++;
      return config;
    }
  };
}

/** @ngInject */
function hp($httpProvider) {
  cov_15lre8za3h.f[3]++;
  cov_15lre8za3h.s[6]++;

  $httpProvider.interceptors.push('AuthInterceptor');
}

/** @ngInject */
function AuthFactory(localStorageService, $cookies, $q, $window) {
  cov_15lre8za3h.f[4]++;

  var config = (cov_15lre8za3h.s[7]++, {
    token: '',
    setToken: setToken,
    getToken: getToken,
    destroyToken: destroySession,
    tokenInterceptor: tokenInterceptor,
    getSimpleToken: getSimpleToken
  });

  function setToken(_token) {
    cov_15lre8za3h.f[5]++;
    cov_15lre8za3h.s[8]++;

    if (localStorageService.isSupported) {
      cov_15lre8za3h.b[1][0]++;
      cov_15lre8za3h.s[9]++;

      return localStorageService.set('SESSION', _token);
    } else {
        cov_15lre8za3h.b[1][1]++;
        cov_15lre8za3h.s[10]++;
        if (localStorageService.cookie.isSupported) {
          cov_15lre8za3h.b[2][0]++;
          cov_15lre8za3h.s[11]++;

          return localStorageService.cookie.set('SESSION', _token);
        } else {
          cov_15lre8za3h.b[2][1]++;
          cov_15lre8za3h.s[12]++;

          return $cookies.put('SESSION', _token, { path: '/' });
        }
      }
  }

  function getSimpleToken() {
    cov_15lre8za3h.f[6]++;
    cov_15lre8za3h.s[13]++;

    if (localStorageService.isSupported) {
      cov_15lre8za3h.b[3][0]++;
      cov_15lre8za3h.s[14]++;

      if (localStorageService.get('SESSION')) {
        cov_15lre8za3h.b[4][0]++;
        cov_15lre8za3h.s[15]++;

        return localStorageService.get('SESSION');
      } else {
        cov_15lre8za3h.b[4][1]++;
        cov_15lre8za3h.s[16]++;

        return '';
      }
    } else {
        cov_15lre8za3h.b[3][1]++;
        cov_15lre8za3h.s[17]++;
        if (localStorageService.cookie.isSupported) {
          cov_15lre8za3h.b[5][0]++;
          cov_15lre8za3h.s[18]++;

          if (localStorageService.cookie.get('SESSION')) {
            cov_15lre8za3h.b[6][0]++;
            cov_15lre8za3h.s[19]++;

            return localStorageService.cookie.get('SESSION');
          } else {
            cov_15lre8za3h.b[6][1]++;
            cov_15lre8za3h.s[20]++;

            return '';
          }
        } else {
            cov_15lre8za3h.b[5][1]++;
            cov_15lre8za3h.s[21]++;
            if ($cookies.get('SESSION')) {
              cov_15lre8za3h.b[7][0]++;
              cov_15lre8za3h.s[22]++;

              return $cookies.get('SESSION');
            } else {
              cov_15lre8za3h.b[7][1]++;
              cov_15lre8za3h.s[23]++;

              return '';
            }
          }
      }
  }

  function getToken() {
    cov_15lre8za3h.f[7]++;
    cov_15lre8za3h.s[24]++;

    if ($window.token) {
      cov_15lre8za3h.b[8][0]++;
      cov_15lre8za3h.s[25]++;

      return 'Bearer ' + $window.token;
    } else {
      cov_15lre8za3h.b[8][1]++;
    }

    cov_15lre8za3h.s[26]++;
    if (localStorageService.isSupported) {
      cov_15lre8za3h.b[9][0]++;
      cov_15lre8za3h.s[27]++;

      if (localStorageService.get('SESSION')) {
        cov_15lre8za3h.b[10][0]++;
        cov_15lre8za3h.s[28]++;

        return 'Bearer ' + localStorageService.get('SESSION');
      } else {
        cov_15lre8za3h.b[10][1]++;
        cov_15lre8za3h.s[29]++;

        return '';
      }
    } else {
        cov_15lre8za3h.b[9][1]++;
        cov_15lre8za3h.s[30]++;
        if (localStorageService.cookie.isSupported) {
          cov_15lre8za3h.b[11][0]++;
          cov_15lre8za3h.s[31]++;

          if (localStorageService.cookie.get('SESSION')) {
            cov_15lre8za3h.b[12][0]++;
            cov_15lre8za3h.s[32]++;

            return 'Bearer ' + localStorageService.cookie.get('SESSION');
          } else {
            cov_15lre8za3h.b[12][1]++;
            cov_15lre8za3h.s[33]++;

            return '';
          }
        } else {
            cov_15lre8za3h.b[11][1]++;
            cov_15lre8za3h.s[34]++;
            if ($cookies.get('SESSION')) {
              cov_15lre8za3h.b[13][0]++;
              cov_15lre8za3h.s[35]++;

              return 'Bearer ' + $cookies.get('SESSION');
            } else {
              cov_15lre8za3h.b[13][1]++;
              cov_15lre8za3h.s[36]++;

              return '';
            }
          }
      }
  }

  function destroySession() {
    cov_15lre8za3h.f[8]++;
    cov_15lre8za3h.s[37]++;

    if (localStorageService.isSupported) {
      cov_15lre8za3h.b[14][0]++;
      cov_15lre8za3h.s[38]++;

      localStorageService.remove('SESSION');
    } else {
        cov_15lre8za3h.b[14][1]++;
        cov_15lre8za3h.s[39]++;
        if (localStorageService.cookie.isSupported) {
          cov_15lre8za3h.b[15][0]++;
          cov_15lre8za3h.s[40]++;

          localStorageService.cookie.remove('SESSION');
        } else {
          cov_15lre8za3h.b[15][1]++;
          cov_15lre8za3h.s[41]++;

          $cookies.remove('SESSION', { path: '/' });
        }
      }
  }

  function tokenInterceptor() {
    cov_15lre8za3h.f[9]++;
    cov_15lre8za3h.s[42]++;

    return {
      response: function response(_response) {
        cov_15lre8za3h.f[10]++;
        cov_15lre8za3h.s[43]++;

        if (_response.headers('X-Auth-Token')) {
          cov_15lre8za3h.b[16][0]++;
          cov_15lre8za3h.s[44]++;

          config.setToken(_response.headers('X-Auth-Token'));
        } else {
          cov_15lre8za3h.b[16][1]++;
        }
        cov_15lre8za3h.s[45]++;
        return (cov_15lre8za3h.b[17][0]++, _response.data) || (cov_15lre8za3h.b[17][1]++, $q.when(_response));
      }
    };
  }

  cov_15lre8za3h.s[46]++;
  return config;
}