'use strict';

var cov_b5hscllv8 = function () {
  var path = '/home/piesevag/volane.piesevag.ro/src/index.js',
      hash = 'bc27e0e73e4ec711f3273c1ddb60c6e206224657',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/home/piesevag/volane.piesevag.ro/src/index.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 3,
          column: 3
        }
      },
      '1': {
        start: {
          line: 2,
          column: 2
        },
        end: {
          line: 2,
          column: 18
        }
      },
      '2': {
        start: {
          line: 4,
          column: 0
        },
        end: {
          line: 11,
          column: 5
        }
      },
      '3': {
        start: {
          line: 13,
          column: 0
        },
        end: {
          line: 17,
          column: 17
        }
      },
      '4': {
        start: {
          line: 21,
          column: 2
        },
        end: {
          line: 23,
          column: 5
        }
      },
      '5': {
        start: {
          line: 24,
          column: 2
        },
        end: {
          line: 52,
          column: 5
        }
      },
      '6': {
        start: {
          line: 25,
          column: 4
        },
        end: {
          line: 25,
          column: 43
        }
      },
      '7': {
        start: {
          line: 26,
          column: 4
        },
        end: {
          line: 51,
          column: 5
        }
      },
      '8': {
        start: {
          line: 29,
          column: 8
        },
        end: {
          line: 29,
          column: 27
        }
      },
      '9': {
        start: {
          line: 30,
          column: 8
        },
        end: {
          line: 30,
          column: 14
        }
      },
      '10': {
        start: {
          line: 33,
          column: 8
        },
        end: {
          line: 33,
          column: 27
        }
      },
      '11': {
        start: {
          line: 34,
          column: 8
        },
        end: {
          line: 34,
          column: 14
        }
      },
      '12': {
        start: {
          line: 37,
          column: 8
        },
        end: {
          line: 37,
          column: 26
        }
      },
      '13': {
        start: {
          line: 38,
          column: 8
        },
        end: {
          line: 38,
          column: 14
        }
      },
      '14': {
        start: {
          line: 41,
          column: 8
        },
        end: {
          line: 41,
          column: 31
        }
      },
      '15': {
        start: {
          line: 42,
          column: 8
        },
        end: {
          line: 42,
          column: 14
        }
      },
      '16': {
        start: {
          line: 45,
          column: 8
        },
        end: {
          line: 45,
          column: 42
        }
      },
      '17': {
        start: {
          line: 46,
          column: 8
        },
        end: {
          line: 46,
          column: 14
        }
      },
      '18': {
        start: {
          line: 49,
          column: 8
        },
        end: {
          line: 49,
          column: 53
        }
      },
      '19': {
        start: {
          line: 50,
          column: 8
        },
        end: {
          line: 50,
          column: 14
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 1,
            column: 46
          },
          end: {
            line: 1,
            column: 47
          }
        },
        loc: {
          start: {
            line: 1,
            column: 52
          },
          end: {
            line: 3,
            column: 1
          }
        },
        line: 1
      },
      '1': {
        name: 'runAccess',
        decl: {
          start: {
            line: 20,
            column: 9
          },
          end: {
            line: 20,
            column: 18
          }
        },
        loc: {
          start: {
            line: 20,
            column: 55
          },
          end: {
            line: 53,
            column: 1
          }
        },
        line: 20
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 21,
            column: 29
          },
          end: {
            line: 21,
            column: 30
          }
        },
        loc: {
          start: {
            line: 21,
            column: 38
          },
          end: {
            line: 23,
            column: 3
          }
        },
        line: 21
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 24,
            column: 27
          },
          end: {
            line: 24,
            column: 28
          }
        },
        loc: {
          start: {
            line: 24,
            column: 43
          },
          end: {
            line: 52,
            column: 3
          }
        },
        line: 24
      },
      '4': {
        name: 'runBlock',
        decl: {
          start: {
            line: 56,
            column: 9
          },
          end: {
            line: 56,
            column: 17
          }
        },
        loc: {
          start: {
            line: 56,
            column: 20
          },
          end: {
            line: 57,
            column: 1
          }
        },
        line: 56
      },
      '5': {
        name: 'configBlock',
        decl: {
          start: {
            line: 60,
            column: 9
          },
          end: {
            line: 60,
            column: 20
          }
        },
        loc: {
          start: {
            line: 60,
            column: 23
          },
          end: {
            line: 61,
            column: 1
          }
        },
        line: 60
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 26,
            column: 4
          },
          end: {
            line: 51,
            column: 5
          }
        },
        type: 'switch',
        locations: [{
          start: {
            line: 28,
            column: 6
          },
          end: {
            line: 30,
            column: 14
          }
        }, {
          start: {
            line: 32,
            column: 6
          },
          end: {
            line: 34,
            column: 14
          }
        }, {
          start: {
            line: 36,
            column: 6
          },
          end: {
            line: 38,
            column: 14
          }
        }, {
          start: {
            line: 40,
            column: 6
          },
          end: {
            line: 42,
            column: 14
          }
        }, {
          start: {
            line: 44,
            column: 6
          },
          end: {
            line: 46,
            column: 14
          }
        }, {
          start: {
            line: 48,
            column: 6
          },
          end: {
            line: 50,
            column: 14
          }
        }],
        line: 26
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0
    },
    b: {
      '0': [0, 0, 0, 0, 0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_b5hscllv8.s[0]++;
angular.module('underscore', []).factory('_', function () {
  cov_b5hscllv8.f[0]++;
  cov_b5hscllv8.s[1]++;

  return window._;
});
cov_b5hscllv8.s[2]++;
angular.module('app', ['ui.router', 'ui.bootstrap', 'LocalStorageModule', 'ngCookies', 'wipImageZoom']);

cov_b5hscllv8.s[3]++;
angular.module('app').config(configBlock).run(runAccess).run(runBlock);

/** @ngInject */
function runAccess($transitions, Access, $log, $state) {
  cov_b5hscllv8.f[1]++;
  cov_b5hscllv8.s[4]++;

  $state.defaultErrorHandler(function (error) {
    // nothing

    cov_b5hscllv8.f[2]++;
  });
  cov_b5hscllv8.s[5]++;
  $transitions.onError({}, function ($transition$) {
    cov_b5hscllv8.f[3]++;
    cov_b5hscllv8.s[6]++;

    console.log($transition$._error.reason);
    cov_b5hscllv8.s[7]++;
    switch ($transition$._error.reason) {

      case Access.UNAUTHORIZED:
        cov_b5hscllv8.b[0][0]++;
        cov_b5hscllv8.s[8]++;

        $state.go('login');
        cov_b5hscllv8.s[9]++;
        break;

      case Access.FORBIDDEN:
        cov_b5hscllv8.b[0][1]++;
        cov_b5hscllv8.s[10]++;

        $state.go('login');
        cov_b5hscllv8.s[11]++;
        break;

      case Access.ENROLLED:
        cov_b5hscllv8.b[0][2]++;
        cov_b5hscllv8.s[12]++;

        $state.go('home');
        cov_b5hscllv8.s[13]++;
        break;

      case Access.NOT_ENROLLED:
        cov_b5hscllv8.b[0][3]++;
        cov_b5hscllv8.s[14]++;

        $state.go('no-course');
        cov_b5hscllv8.s[15]++;
        break;

      case Access.MULTIPLE:
        cov_b5hscllv8.b[0][4]++;
        cov_b5hscllv8.s[16]++;

        $state.go('isf-users-management');
        cov_b5hscllv8.s[17]++;
        break;

      default:
        cov_b5hscllv8.b[0][5]++;
        cov_b5hscllv8.s[18]++;

        $log.warn('$stateChangeError event catched');
        cov_b5hscllv8.s[19]++;
        break;
    }
  });
}

/** @ngInject */
function runBlock() {
  cov_b5hscllv8.f[4]++;
}

/** @ngInject */
function configBlock() {
  cov_b5hscllv8.f[5]++;
}