'use strict';

var cov_1w5c1zeq5j = function () {
  var path = '/home/piesevag/volane.piesevag.ro/src/app/login/login.js',
      hash = '1fa1ffe6553a3f45579f0e3b08c587b77f6639c7',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/home/piesevag/volane.piesevag.ro/src/app/login/login.js',
    statementMap: {
      '0': {
        start: {
          line: 4,
          column: 15
        },
        end: {
          line: 4,
          column: 19
        }
      },
      '1': {
        start: {
          line: 6,
          column: 4
        },
        end: {
          line: 6,
          column: 17
        }
      },
      '2': {
        start: {
          line: 8,
          column: 4
        },
        end: {
          line: 18,
          column: 6
        }
      },
      '3': {
        start: {
          line: 9,
          column: 6
        },
        end: {
          line: 17,
          column: 11
        }
      },
      '4': {
        start: {
          line: 11,
          column: 10
        },
        end: {
          line: 16,
          column: 11
        }
      },
      '5': {
        start: {
          line: 12,
          column: 12
        },
        end: {
          line: 12,
          column: 49
        }
      },
      '6': {
        start: {
          line: 13,
          column: 12
        },
        end: {
          line: 15,
          column: 21
        }
      },
      '7': {
        start: {
          line: 14,
          column: 14
        },
        end: {
          line: 14,
          column: 31
        }
      },
      '8': {
        start: {
          line: 22,
          column: 0
        },
        end: {
          line: 28,
          column: 5
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 3,
            column: 2
          },
          end: {
            line: 3,
            column: 3
          }
        },
        loc: {
          start: {
            line: 3,
            column: 59
          },
          end: {
            line: 19,
            column: 3
          }
        },
        line: 3
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 8,
            column: 15
          },
          end: {
            line: 8,
            column: 16
          }
        },
        loc: {
          start: {
            line: 8,
            column: 21
          },
          end: {
            line: 18,
            column: 5
          }
        },
        line: 8
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 10,
            column: 14
          },
          end: {
            line: 10,
            column: 15
          }
        },
        loc: {
          start: {
            line: 10,
            column: 21
          },
          end: {
            line: 17,
            column: 9
          }
        },
        line: 10
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 13,
            column: 21
          },
          end: {
            line: 13,
            column: 22
          }
        },
        loc: {
          start: {
            line: 13,
            column: 27
          },
          end: {
            line: 15,
            column: 13
          }
        },
        line: 13
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 11,
            column: 10
          },
          end: {
            line: 16,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 11,
            column: 10
          },
          end: {
            line: 16,
            column: 11
          }
        }, {
          start: {
            line: 11,
            column: 10
          },
          end: {
            line: 16,
            column: 11
          }
        }],
        line: 11
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0
    },
    b: {
      '0': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Login =
/** @ngInject */
function Login(LoginFactory, $state, AuthFactory, $timeout) {
  _classCallCheck(this, Login);

  cov_1w5c1zeq5j.f[0]++;

  var vm = (cov_1w5c1zeq5j.s[0]++, this);

  cov_1w5c1zeq5j.s[1]++;
  vm.user = {};

  cov_1w5c1zeq5j.s[2]++;
  vm.login = function () {
    cov_1w5c1zeq5j.f[1]++;
    cov_1w5c1zeq5j.s[3]++;

    LoginFactory.auth(vm.user).then(function (rsp) {
      cov_1w5c1zeq5j.f[2]++;
      cov_1w5c1zeq5j.s[4]++;

      if (!rsp.data.success) {
        cov_1w5c1zeq5j.b[0][0]++;
        cov_1w5c1zeq5j.s[5]++;

        AuthFactory.setToken(rsp.data.token);
        cov_1w5c1zeq5j.s[6]++;
        $timeout(function () {
          cov_1w5c1zeq5j.f[3]++;
          cov_1w5c1zeq5j.s[7]++;

          $state.go('app');
        }, 1500);
      } else {
        cov_1w5c1zeq5j.b[0][1]++;
      }
    });
  };
};

cov_1w5c1zeq5j.s[8]++;


angular.module('app').component('login', {
  templateUrl: 'app/login/login.html',
  controller: Login,
  controllerAs: 'vm'
});