'use strict';

var cov_kmmewzxva = function () {
  var path = '/home/piesevag/volane.piesevag.ro/src/app/product-page/product.js',
      hash = '73bac399a645fb96e424129b927367aed9c3a584',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/home/piesevag/volane.piesevag.ro/src/app/product-page/product.js',
    statementMap: {
      '0': {
        start: {
          line: 4,
          column: 15
        },
        end: {
          line: 4,
          column: 19
        }
      },
      '1': {
        start: {
          line: 6,
          column: 4
        },
        end: {
          line: 6,
          column: 15
        }
      },
      '2': {
        start: {
          line: 8,
          column: 4
        },
        end: {
          line: 49,
          column: 9
        }
      },
      '3': {
        start: {
          line: 10,
          column: 8
        },
        end: {
          line: 10,
          column: 39
        }
      },
      '4': {
        start: {
          line: 12,
          column: 8
        },
        end: {
          line: 16,
          column: 10
        }
      },
      '5': {
        start: {
          line: 18,
          column: 8
        },
        end: {
          line: 18,
          column: 43
        }
      },
      '6': {
        start: {
          line: 21,
          column: 8
        },
        end: {
          line: 21,
          column: 27
        }
      },
      '7': {
        start: {
          line: 22,
          column: 8
        },
        end: {
          line: 28,
          column: 11
        }
      },
      '8': {
        start: {
          line: 23,
          column: 10
        },
        end: {
          line: 27,
          column: 12
        }
      },
      '9': {
        start: {
          line: 29,
          column: 8
        },
        end: {
          line: 48,
          column: 10
        }
      },
      '10': {
        start: {
          line: 51,
          column: 4
        },
        end: {
          line: 56,
          column: 6
        }
      },
      '11': {
        start: {
          line: 52,
          column: 6
        },
        end: {
          line: 55,
          column: 11
        }
      },
      '12': {
        start: {
          line: 54,
          column: 10
        },
        end: {
          line: 54,
          column: 49
        }
      },
      '13': {
        start: {
          line: 60,
          column: 0
        },
        end: {
          line: 66,
          column: 5
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 3,
            column: 2
          },
          end: {
            line: 3,
            column: 3
          }
        },
        loc: {
          start: {
            line: 3,
            column: 68
          },
          end: {
            line: 57,
            column: 3
          }
        },
        line: 3
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 9,
            column: 12
          },
          end: {
            line: 9,
            column: 13
          }
        },
        loc: {
          start: {
            line: 9,
            column: 19
          },
          end: {
            line: 49,
            column: 7
          }
        },
        line: 9
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 22,
            column: 30
          },
          end: {
            line: 22,
            column: 31
          }
        },
        loc: {
          start: {
            line: 22,
            column: 40
          },
          end: {
            line: 28,
            column: 9
          }
        },
        line: 22
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 51,
            column: 19
          },
          end: {
            line: 51,
            column: 20
          }
        },
        loc: {
          start: {
            line: 51,
            column: 25
          },
          end: {
            line: 56,
            column: 5
          }
        },
        line: 51
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 53,
            column: 14
          },
          end: {
            line: 53,
            column: 15
          }
        },
        loc: {
          start: {
            line: 53,
            column: 21
          },
          end: {
            line: 55,
            column: 9
          }
        },
        line: 53
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Product =
/** @ngInject */
function Product(MainFactory, $stateParams, $cookies, env, $rootScope) {
  _classCallCheck(this, Product);

  cov_kmmewzxva.f[0]++;

  var vm = (cov_kmmewzxva.s[0]++, this);

  cov_kmmewzxva.s[1]++;
  vm.qty = 1;

  cov_kmmewzxva.s[2]++;
  MainFactory.wheels({ id: $stateParams.id }).then(function (rsp) {
    cov_kmmewzxva.f[1]++;
    cov_kmmewzxva.s[3]++;

    vm.product = rsp.data.response;

    cov_kmmewzxva.s[4]++;
    vm.selected = {
      large: vm.product.images[0].original,
      thumb: vm.product.images[0].small,
      medium: vm.product.images[0].large_constrained
    };

    cov_kmmewzxva.s[5]++;
    vm.smallImages = vm.product.images;

    // convert images to zoom object
    cov_kmmewzxva.s[6]++;
    vm.zoomImages = [];
    cov_kmmewzxva.s[7]++;
    vm.product.images.map(function (o, i) {
      cov_kmmewzxva.f[2]++;
      cov_kmmewzxva.s[8]++;

      vm.zoomImages[i] = {
        thumb: o.small,
        medium: o.large_constrained,
        large: o.original
      };
    });
    cov_kmmewzxva.s[9]++;
    vm.options = {
      zoomEnable: true,
      defaultIndex: 0, // Order of the default selected Image
      /* You should give images in array with object properties
       [{
       thumb : 'assets/images/1-thumb.jpg',
       medium: 'assets/images/1-medium.jpg',
       large : 'assets/images/1-large.jpg'
       }] */
      images: vm.zoomImages,
      style: 'box', // inner or box
      boxPos: 'right-top', // e.g., right-top, right-middle, right-bottom, top-center, top-left, top-right ...
      boxW: 550, // Box width
      boxH: 400, // Box height
      method: 'lens', // follow 'lens' or 'pointer'
      thumbCol: 5,
      cursor: 'crosshair', // 'none', 'default', 'crosshair', 'pointer', 'move'
      lens: true, // Lens toggle
      zoomLevel: 3 // 0: not scales, uses the original large image size, use 1 and above to adjust.
    };
  });

  cov_kmmewzxva.s[10]++;
  vm.addToCart = function () {
    cov_kmmewzxva.f[3]++;
    cov_kmmewzxva.s[11]++;

    MainFactory.cartAdd({ id: $stateParams.id }).then(function (rsp) {
      cov_kmmewzxva.f[4]++;
      cov_kmmewzxva.s[12]++;

      $rootScope.$broadcast('added-to-cart');
    });
  };
};

cov_kmmewzxva.s[13]++;


angular.module('app').component('product', {
  templateUrl: 'app/product-page/product.html',
  controller: Product,
  controllerAs: 'vm'
});