'use strict';

var cov_2j75uxvmrg = function () {
  var path = '/home/piesevag/volane.piesevag.ro/src/app/cart/cart.js',
      hash = 'ef627c5e562f2fa6e067300805be06539216493f',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/home/piesevag/volane.piesevag.ro/src/app/cart/cart.js',
    statementMap: {
      '0': {
        start: {
          line: 4,
          column: 15
        },
        end: {
          line: 4,
          column: 19
        }
      },
      '1': {
        start: {
          line: 6,
          column: 4
        },
        end: {
          line: 9,
          column: 9
        }
      },
      '2': {
        start: {
          line: 8,
          column: 8
        },
        end: {
          line: 8,
          column: 32
        }
      },
      '3': {
        start: {
          line: 11,
          column: 4
        },
        end: {
          line: 19,
          column: 6
        }
      },
      '4': {
        start: {
          line: 12,
          column: 6
        },
        end: {
          line: 18,
          column: 11
        }
      },
      '5': {
        start: {
          line: 14,
          column: 10
        },
        end: {
          line: 14,
          column: 23
        }
      },
      '6': {
        start: {
          line: 15,
          column: 10
        },
        end: {
          line: 17,
          column: 19
        }
      },
      '7': {
        start: {
          line: 16,
          column: 12
        },
        end: {
          line: 16,
          column: 29
        }
      },
      '8': {
        start: {
          line: 21,
          column: 4
        },
        end: {
          line: 32,
          column: 6
        }
      },
      '9': {
        start: {
          line: 22,
          column: 6
        },
        end: {
          line: 31,
          column: 11
        }
      },
      '10': {
        start: {
          line: 24,
          column: 10
        },
        end: {
          line: 26,
          column: 13
        }
      },
      '11': {
        start: {
          line: 25,
          column: 12
        },
        end: {
          line: 25,
          column: 31
        }
      },
      '12': {
        start: {
          line: 28,
          column: 10
        },
        end: {
          line: 30,
          column: 11
        }
      },
      '13': {
        start: {
          line: 29,
          column: 12
        },
        end: {
          line: 29,
          column: 29
        }
      },
      '14': {
        start: {
          line: 36,
          column: 0
        },
        end: {
          line: 42,
          column: 5
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 3,
            column: 2
          },
          end: {
            line: 3,
            column: 3
          }
        },
        loc: {
          start: {
            line: 3,
            column: 45
          },
          end: {
            line: 33,
            column: 3
          }
        },
        line: 3
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 7,
            column: 12
          },
          end: {
            line: 7,
            column: 13
          }
        },
        loc: {
          start: {
            line: 7,
            column: 19
          },
          end: {
            line: 9,
            column: 7
          }
        },
        line: 7
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 11,
            column: 19
          },
          end: {
            line: 11,
            column: 20
          }
        },
        loc: {
          start: {
            line: 11,
            column: 27
          },
          end: {
            line: 19,
            column: 5
          }
        },
        line: 11
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 13,
            column: 14
          },
          end: {
            line: 13,
            column: 15
          }
        },
        loc: {
          start: {
            line: 13,
            column: 21
          },
          end: {
            line: 18,
            column: 9
          }
        },
        line: 13
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 15,
            column: 19
          },
          end: {
            line: 15,
            column: 20
          }
        },
        loc: {
          start: {
            line: 15,
            column: 25
          },
          end: {
            line: 17,
            column: 11
          }
        },
        line: 15
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 21,
            column: 23
          },
          end: {
            line: 21,
            column: 24
          }
        },
        loc: {
          start: {
            line: 21,
            column: 29
          },
          end: {
            line: 32,
            column: 5
          }
        },
        line: 21
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 23,
            column: 14
          },
          end: {
            line: 23,
            column: 15
          }
        },
        loc: {
          start: {
            line: 23,
            column: 20
          },
          end: {
            line: 31,
            column: 9
          }
        },
        line: 23
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 24,
            column: 35
          },
          end: {
            line: 24,
            column: 36
          }
        },
        loc: {
          start: {
            line: 24,
            column: 40
          },
          end: {
            line: 26,
            column: 11
          }
        },
        line: 24
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 28,
            column: 10
          },
          end: {
            line: 30,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 28,
            column: 10
          },
          end: {
            line: 30,
            column: 11
          }
        }, {
          start: {
            line: 28,
            column: 10
          },
          end: {
            line: 30,
            column: 11
          }
        }],
        line: 28
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0
    },
    b: {
      '0': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Cart =
/** @ngInject */
function Cart(MainFactory, $state, $timeout) {
  _classCallCheck(this, Cart);

  cov_2j75uxvmrg.f[0]++;

  var vm = (cov_2j75uxvmrg.s[0]++, this);

  cov_2j75uxvmrg.s[1]++;
  MainFactory.cartContent().then(function (rsp) {
    cov_2j75uxvmrg.f[1]++;
    cov_2j75uxvmrg.s[2]++;

    vm.cart = rsp.data.cart;
  });

  cov_2j75uxvmrg.s[3]++;
  vm.saveOrder = function (cart) {
    cov_2j75uxvmrg.f[2]++;
    cov_2j75uxvmrg.s[4]++;

    MainFactory.saveOrder({ cart: cart }).then(function (rsp) {
      cov_2j75uxvmrg.f[3]++;
      cov_2j75uxvmrg.s[5]++;

      vm.cart = [];
      cov_2j75uxvmrg.s[6]++;
      $timeout(function () {
        cov_2j75uxvmrg.f[4]++;
        cov_2j75uxvmrg.s[7]++;

        $state.go('app');
      }, 1500);
    });
  };

  cov_2j75uxvmrg.s[8]++;
  vm.removeCartRow = function (id) {
    cov_2j75uxvmrg.f[5]++;
    cov_2j75uxvmrg.s[9]++;

    MainFactory.removeCart({ id: id }).then(function () {
      cov_2j75uxvmrg.f[6]++;
      cov_2j75uxvmrg.s[10]++;

      vm.cart = vm.cart.filter(function (o) {
        cov_2j75uxvmrg.f[7]++;
        cov_2j75uxvmrg.s[11]++;

        return o.id !== id;
      });

      cov_2j75uxvmrg.s[12]++;
      if (vm.cart.length === 0) {
        cov_2j75uxvmrg.b[0][0]++;
        cov_2j75uxvmrg.s[13]++;

        $state.go('app');
      } else {
        cov_2j75uxvmrg.b[0][1]++;
      }
    });
  };
};

cov_2j75uxvmrg.s[14]++;


angular.module('app').component('cart', {
  templateUrl: 'app/cart/cart.html',
  controller: Cart,
  controllerAs: 'vm'
});