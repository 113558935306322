'use strict';

var cov_t3tsyxvir = function () {
  var path = '/home/piesevag/volane.piesevag.ro/src/app/product-page/product.factory.js',
      hash = 'db6e298f82241de02474628905b63bb2032ced08',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/home/piesevag/volane.piesevag.ro/src/app/product-page/product.factory.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 3,
          column: 45
        }
      },
      '1': {
        start: {
          line: 7,
          column: 2
        },
        end: {
          line: 8,
          column: 4
        }
      }
    },
    fnMap: {
      '0': {
        name: 'ProductFactory',
        decl: {
          start: {
            line: 6,
            column: 9
          },
          end: {
            line: 6,
            column: 23
          }
        },
        loc: {
          start: {
            line: 6,
            column: 36
          },
          end: {
            line: 9,
            column: 1
          }
        },
        line: 6
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0
    },
    f: {
      '0': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_t3tsyxvir.s[0]++;
angular.module('app').factory('ProductFactory', ProductFactory);

/** @ngInject */
function ProductFactory(Api, $http) {
  cov_t3tsyxvir.f[0]++;
  cov_t3tsyxvir.s[1]++;

  return {};
}