'use strict';

var cov_1pfcsmfkg6 = function () {
  var path = '/home/piesevag/volane.piesevag.ro/src/app/components/small-product-box/small-product-box.js',
      hash = '844d738f6109a915c3b14bb29f5accccc43a24c7',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/home/piesevag/volane.piesevag.ro/src/app/components/small-product-box/small-product-box.js',
    statementMap: {
      '0': {
        start: {
          line: 4,
          column: 15
        },
        end: {
          line: 4,
          column: 19
        }
      },
      '1': {
        start: {
          line: 6,
          column: 4
        },
        end: {
          line: 7,
          column: 6
        }
      },
      '2': {
        start: {
          line: 9,
          column: 4
        },
        end: {
          line: 11,
          column: 6
        }
      },
      '3': {
        start: {
          line: 10,
          column: 6
        },
        end: {
          line: 10,
          column: 33
        }
      },
      '4': {
        start: {
          line: 15,
          column: 0
        },
        end: {
          line: 25,
          column: 5
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 3,
            column: 2
          },
          end: {
            line: 3,
            column: 3
          }
        },
        loc: {
          start: {
            line: 3,
            column: 27
          },
          end: {
            line: 12,
            column: 3
          }
        },
        line: 3
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 6,
            column: 17
          },
          end: {
            line: 6,
            column: 18
          }
        },
        loc: {
          start: {
            line: 6,
            column: 23
          },
          end: {
            line: 7,
            column: 5
          }
        },
        line: 6
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 9,
            column: 21
          },
          end: {
            line: 9,
            column: 22
          }
        },
        loc: {
          start: {
            line: 9,
            column: 35
          },
          end: {
            line: 11,
            column: 5
          }
        },
        line: 9
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var SmallProductBoxController =
/** @ngInject */
function SmallProductBoxController(env, $state) {
  _classCallCheck(this, SmallProductBoxController);

  cov_1pfcsmfkg6.f[0]++;

  var vm = (cov_1pfcsmfkg6.s[0]++, this);

  cov_1pfcsmfkg6.s[1]++;
  vm.$onInit = function () {
    cov_1pfcsmfkg6.f[1]++;
  };

  cov_1pfcsmfkg6.s[2]++;
  vm.goToProduct = function (id) {
    cov_1pfcsmfkg6.f[2]++;
    cov_1pfcsmfkg6.s[3]++;

    $state.go('product', { id: id });
  };
};

cov_1pfcsmfkg6.s[4]++;


angular.module('app').component('smallProductBox', {
  templateUrl: 'app/components/small-product-box/small-product-box.html',
  controller: SmallProductBoxController,
  controllerAs: 'vm',
  bindToController: true,
  bindings: {
    product: '='
  }
});